<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="column q-gutter-md">
        <q-select
          outlined
          label="Jenjang"
          v-model="selJenjang"
          :options="$LIST_JENJANG"
        ></q-select>
      </q-card-section>
      <q-card-section>
        <q-markup-table flat>
          <tr>
            <td>DROP OUT</td>
            <td>:</td>
            <td>{{ filteredLog.jml_do }}</td>
          </tr>
          <tr>
            <td>PINDAH</td>
            <td>:</td>
            <td>{{ filteredLog.jml_pindah }}</td>
          </tr>
          <tr>
            <td>CUTI</td>
            <td>:</td>
            <td>{{ filteredLog.jml_cuti }}</td>
          </tr>
        </q-markup-table>

        <q-select
          outlined
          label="Status"
          :options="optStatus"
          v-model="selStatus"
          class="q-mt-md"
        ></q-select>
      </q-card-section>
      <q-markup-table
        wrap-cells
        dense
        separator="horizontal"
        class="stickyTable"
        style="height:calc(100vh - 350px)"
      >
        <thead>
          <tr>
            <th>No</th>
            <th>Nama</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, i) in filteredLog.show_siswa" :key="i">
            <td>{{ (pagination.current - 1) * pagination.limit + i + 1 }}</td>
            <td>
              <div class="column">
                <a>{{ val.nama }}</a>
                <a>{{ val.nisn }}</a>
              </div>
            </td>
            <td>{{ val.status_akhir }}</td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions align="center">
        <q-pagination
          v-model="pagination.current"
          :max="pagination.max"
          input
        />
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import General from "@/mixins/General";
export default {
  mixins: [General],
  data() {
    return {
      selJenjang: this.$LIST_JENJANG[0],

      selStatus: "SEMUA",
      optStatus: ["SEMUA", "DROP OUT", "PINDAH", "CUTI"],

      optTahunAjar: [],
      selTahunAjar: null,

      dataDrop: [],
      dataPindah: [],
      dataCuti: [],
      dataAll: [],

      lenDrop: 0,
      lenPindah: 0,
      lenCuti: 0,

      show: [],

      status_log: [],
      range: { from: null, to: null },

      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.getLog();
    this.$q.loading.hide();
  },
  computed: {
    filteredLog() {
      let temp = JSON.parse(JSON.stringify(this.status_log));

      temp = temp.filter((val) => {
        return val.jenjang == this.selJenjang;
      });

      if (this.range.from && this.range.to) {
        temp = temp.filter((val) => {
          return (
            moment(val.waktu) >= moment(this.range.from) &&
            moment(val.waktu) <= moment(this.range.to)
          );
        });
      }

      let data = {};

      data.jml_do = temp.filter((val) => {
        return val.status_akhir == "DROP OUT";
      }).length;

      data.jml_cuti = temp.filter((val) => {
        return val.status_akhir == "CUTI";
      }).length;

      data.jml_pindah = temp.filter((val) => {
        return val.status_akhir == "PINDAH";
      }).length;

      if (this.selStatus != "SEMUA") {
        temp = temp.filter((val) => {
          return val.status_akhir == this.selStatus;
        });
      }
      data.list_siswa = temp;
      data.show_siswa = [];

      for (let i = 0; i < 10; i++) {
        if (temp[(this.pagination.current - 1) * 10 + i]) {
          data.show_siswa.push(temp[(this.pagination.current - 1) * 10 + i]);
        }
      }

      this.pagination.max = Math.ceil(temp.length / 10);

      return data;
    },
  },
  methods: {
    async getLog() {
      let resp = await this.$http.get(`/statistik/statussiswa/getlog`);
      this.status_log = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.scss";
</style>
